<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Modifica rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="modificaRapportino()">
                    <ion-list class="fields">
                        <!-- Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Data</span>
                                </div>
                                <div class="value">
                                    {{ dateFormat(toEdit.rapportini_data) }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Cliente</span>
                                </div>
                                <div class="value">
                                    {{
                                        toEdit.customers_company ? toEdit.customers_company : `${toEdit.customers_name} ${selectedCustomer.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Commessa</span>
                                </div>
                                <div class="value">
                                    {{ toEdit.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Note -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Immagini rapportino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="rapportino.immagini.length != 0">
                            <div class="field">
                                <div class="field_title" position="floating">Immagini</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in rapportino.immagini" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button
                                v-if="toEdit.projects_richiesta_firma_cliente && toEdit.projects_richiesta_firma_cliente == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: customerHasSigned }"
                                @click="openSignature('cliente')"
                            >
                                Firma cliente
                            </button>
                            <button
                                v-if="toEdit.projects_richiesta_firma_operatore && toEdit.projects_richiesta_firma_operatore == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: technicianHasSigned }"
                                @click="openSignature('tecnico')"
                            >
                                Firma operatore
                            </button>
                        </div>

                        <div class="action">
                            <!-- <div class="btn_allega_foto" @click="openModalPhotos()">Carica foto</div> -->
                            <button type="submit" class="btn_modifica_rapportino" :disabled="savingRapportino">
                                Modifica rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonThumbnail,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiRapportini from "@/services/rapportini";

import moment from "moment";

import ModalFirmaIntervento from "@/components/ModalFirmaIntervento";
import ImagesModal from "@/components/rapportini/FotoRapportino";

export default defineComponent({
    name: "ModificaRapportino",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonThumbnail,
        IonIcon,
    },
    setup(props, contenxt) {
        //console.log(props);
        const toEdit = { ...props.data };
        //console.log(toEdit);

        const today = moment().format("YYYY-MM-DD");
        const start_hour = moment()
            .subtract(2, "hours")
            .format("YYYY-MM-DD HH:mm");
        const end_hour = moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            ora_inizio: toEdit.rapportini_ora_inizio,
            ora_fine: toEdit.rapportini_ora_fine,
            note: toEdit.rapportini_note,
            firma_tecnico: toEdit.rapportini_firma_operatore,
            firma_cliente: toEdit.rapportini_firma_cliente,
            immagini: [],
        });

        const successResponse = ref(false);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            //console.log(res);
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Open modal to sign
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);

        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? rapportino.firma_tecnico : rapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            rapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            rapportino.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    images: immaginiRapportino.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            rapportino.immagini.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            rapportino.immagini = rapportino.immagini.filter((immagine) => immagine.name !== photo);
        }

        /**
         * ! Create new rapportino
         */
        const savingRapportino = ref(false);

        async function modificaRapportino() {
            savingRapportino.value = true;
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(`${toEdit.rapportini_data} ${rapportino.ora_inizio}`);
            const ora_fine = new Date(`${toEdit.rapportini_data} ${rapportino.ora_fine}`);
            /* if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            } */

            const ora_inizio_formatted = moment(ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);

            if (rapportino.note) {
                data.append("rapportini_note", rapportino.note);
            }

            if (rapportino.immagini.length != 0) {
                rapportino.immagini.forEach((foto) => {
                    data.append("rapportini_immagini[]", foto.data);
                });
            }

            if (rapportino.firma_tecnico) {
                data.append("rapportini_firma_operatore", rapportino.firma_tecnico);
            }
            if (rapportino.firma_cliente) {
                data.append("rapportini_firma_cliente", rapportino.firma_cliente);
            }

            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return */
            try {
                const response = await apiRapportini.editRapportino(data, toEdit.rapportini_id);
                //console.log(response);
                if (response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                savingRapportino.value = false;
                openToast("Errore durante la modifica del rapportino", "toast_danger");
            } finally {
                setTimeout(() => {
                    savingRapportino.value = false;
                }, 1000);
            }
        }

        onMounted(() => {
            technicianHasSigned.value = toEdit.rapportini_firma_operatore ? true : false;
            customerHasSigned.value = toEdit.rapportini_firma_cliente ? true : false;
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            modificaRapportino,
            savingRapportino,
            rapportino,
            dateFormat,
            //MODIFICA RAPPORTINO
            toEdit,
            //Foto
            openModalPhotos,
            deletePhoto,
            // Firme
            openSignature,
            customerHasSigned,
            technicianHasSigned,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_modifica_rapportino {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_modifica_rapportino:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(62, 160, 208);
    color: #ffffff;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.customer {
    padding: 6px;
}
.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}
</style>
