<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="saveRapportino()">
                    <ion-list class="fields">
                        <!-- Data -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Orari appuntamento</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ dayMonthFormat(appuntamento.appuntamenti_giorno) }}, {{ appuntamento.appuntamenti_ora_inizio }} -
                                    {{ appuntamento.appuntamenti_ora_fine }} - Ore previste:
                                    {{ oreTotaliAppuntamento(appuntamento) }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Cliente</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ appuntamento.full_name ? appuntamento.full_name : `${appuntamento.customers_name} ${appuntamento.customers_last_name}` }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetto / Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    <span>Commessa</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ appuntamento.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Operatori -->
                        <ion-item v-if="richiediOperatori" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Operatori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select multiple cancel-text="Annulla" ok-text="Conferma" v-model="rapportino.operatori">
                                        <ion-select-option v-for="(tecnico, index) in operatori" :key="index" :value="tecnico.users_id">
                                            {{ `${tecnico.users_last_name} ${tecnico.users_first_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_fine"
                                        placeholder="Seleziona ora di fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Checklist rapportino basata sul tipo commessa -->
                        <div v-if="loadingSondaggio" class="load_sondaggio_container">
                            <div class="load_sondaggio_text">
                                Ricerca checklist in corso...
                            </div>
                        </div>

                        <div v-if="sondaggio" class="checklist_container">
                            <div v-for="(step, i) in sondaggio" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]"> </ion-textarea>
                                    </div>

                                    <!-- Select (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ `${option.sondaggi_domande_risposte_risposta}` }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>

                                    <!-- Select (scelta multipla) -->
                                    <!-- <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-radio-group
                                            :value="domanda.risposte[0].sondaggi_domande_risposte_id"
                                            @ionChange="setCheckboxRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-checkbox
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-checkbox>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div> -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-select
                                            multiple
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                            @ionChange="setMultipleSelectRisposta($event, domanda.sondaggi_domande_id)"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Immagini rapportino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="immaginiRapportino.length != 0">
                            <div class="field">
                                <div class="field_title" position="floating">Immagini</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in immaginiRapportino" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <!-- <img :src="setImageUrl(foto)" /> -->
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <!-- <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div> -->
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button
                                v-if="appuntamento.projects_richiesta_firma_cliente && appuntamento.projects_richiesta_firma_cliente == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: customerHasSigned }"
                                @click="openSignature('cliente')"
                            >
                                Firma cliente
                            </button>
                            <button
                                v-if="appuntamento.projects_richiesta_firma_operatore && appuntamento.projects_richiesta_firma_operatore == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: technicianHasSigned }"
                                @click="openSignature('tecnico')"
                            >
                                Firma operatore
                            </button>
                        </div>

                        <div class="action">
                            <!-- <div class="btn_allega_foto" @click="openModalPhotos()">Carica foto</div> -->
                            <button type="submit" class="btn_crea_intervento" :disabled="savingRapportino">
                                Salva rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonThumbnail,
    IonIcon,
    IonInput,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    //IonCheckbox,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";

import moment from "moment";

import { openToast } from "@/services/toast";
import { dayMonthFormat } from "@/services/utils";
import Loading from "@/services/loading";

import apiPlan from "@/services/impostazioni_plan";
import apiTecnici from "@/services/tecnici";
import apiRapportini from "@/services/rapportini";
//import apiServiziCommessa from "@/custom/services/servizi_commessa";
import apiSondaggi from "@/services/sondaggi";

import ModalFirmaIntervento from "@/components/ModalFirmaIntervento";
import FotoRapportino from "@/components/rapportini/FotoRapportino";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino";

export default defineComponent({
    name: "NuovoRapportinoAppuntamento",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonThumbnail,
        IonIcon,
        IonInput,
        IonLabel,
        IonRadio,
        IonRadioGroup,
        IonSelect,
        IonSelectOption,
        //IonCheckbox,
    },
    setup(props, context) {
        //console.log(props.data);
        const appuntamento = { ...props.data };

        const userID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_id;

        //Ora di inizio e fine impostata agli orari dell'appuntamento
        const today = moment().format("YYYY-MM-DD");
        const start_hour = appuntamento.appuntamenti_ora_inizio
            ? moment(`${today} ${appuntamento.appuntamenti_ora_inizio}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");
        const end_hour = appuntamento.appuntamenti_ora_fine
            ? moment(`${today} ${appuntamento.appuntamenti_ora_fine}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            cliente: appuntamento.appuntamenti_cliente,
            appuntamento: appuntamento.appuntamenti_id,
            commessa: appuntamento.appuntamenti_impianto,
            data: moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD"),
            ora_inizio: moment(start_hour).toISOString(),
            ora_fine: moment(end_hour).toISOString(),
            operatori: [userID],
            note: "",
            immagini: [],
            firma_tecnico: "",
            firma_cliente: "",
        });

        const successResponse = ref(false);
        const savingRapportino = ref(false);

        /********************************************
         *
         * ! IMPOSTAZIONI MODULO PLAN e OPERATORI
         *
         ********************************************/
        const richiediOperatori = ref(false);
        async function loadImpostazioni() {
            const res = await apiPlan.getImpostazioni();
            if (res.status === 200 && res.data.status === 0) {
                //settings.value = res.data.data;
                if (res.data.data.length != 0) {
                    richiediOperatori.value =
                        res.data.data[0].impostazioni_plan_richiedi_operatori && res.data.data[0].impostazioni_plan_richiedi_operatori == 1 ? true : false;
                }
            }
        }
        loadImpostazioni();

        const operatori = ref([]);
        async function loadTecnici() {
            const res = await apiTecnici.getTecnici();
            if (res.status === 200 && res.data.status === 0) {
                operatori.value = res.data.data;
            }
        }
        loadTecnici();

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Calcolo ore totali a titolo informativo
         * @param {appuntamento object} appuntamento
         */
        function oreTotaliAppuntamento(appuntamento) {
            if (appuntamento.appuntamenti_ora_inizio && appuntamento.appuntamenti_ora_fine) {
                const data = moment().format("YYYY-MM-DD");
                const ora_inizio = moment(`${data} ${appuntamento.appuntamenti_ora_inizio}`);
                const ora_fine = moment(`${data} ${appuntamento.appuntamenti_ora_fine}`);
                const duration = moment.duration(ora_fine.diff(ora_inizio));
                //return moment.duration(ora_fine.diff(ora_inizio));
                return duration.hours() + "." + duration.minutes();
            } else {
                return "-";
            }
        }

        /**
         * ! Open modal to sign
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);

        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? rapportino.firma_tecnico : rapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            rapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            rapportino.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: FotoRapportino, //Component to render inside ionic modal
                componentProps: {
                    images: immaginiRapportino.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            immaginiRapportino.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            rapportino.immagini = rapportino.immagini.filter((immagine) => immagine.name !== photo);
        }

        /**
         * ! Ricerca sondaggio associato alla commessa selezionata
         */
        const sondaggio = ref(null);
        const loadingSondaggio = ref(false);
        async function loadSondaggio(appuntamento_id) {
            loadingSondaggio.value = true;
            //Disabilito button per evitare creazione rapportino se la richiesta della checklist ci mette troppo
            savingRapportino.value = true;

            try {
                const res = await apiSondaggi.getSondaggioAppuntamento(appuntamento_id);
                console.log(res);
                if (res.data.status === 1) {
                    sondaggio.value = res.data.data;
                } else {
                    openToast(`Checklist non riconosciuta. Contattare l'assistenza`, "toast_danger");
                    //openToast(`${res.data.txt}`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta della checklist", "toast_danger");
            } finally {
                loadingSondaggio.value = false;
                savingRapportino.value = false;
            }
        }

        /**
         * ! Contenitore v-model del form
         * */
        const formFields = ref({});
        /**
         * ! Set risposta value based on selected radio
         */
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }
        /**
         * ! Set risposta value based on selected checkbox
         * ? Devo costruirmi un array con le risposte
         */
        let risposteCheckbox = [];
        function setCheckboxRisposta(event, reference) {
            const value = event.target.value;
            //formFields.value[reference] = value;

            //Se sto deselezionando tolgo risposta dall'array
            if (risposteCheckbox.includes(value)) {
                const tmp = risposteCheckbox.filter((el) => el == value);
                risposteCheckbox = tmp;
            } else {
                //Sto aggiungendo
                risposteCheckbox.push(value);
            }

            formFields.value[reference] = [...risposteCheckbox];

            /* const value = event.target.value;
            risposteCheckbox.push(value);
            formFields.value[reference] = risposteCheckbox; */
        }

        function setMultipleSelectRisposta(selectedValues, domandaId) {
            // Gestisci la selezione multipla qui, ad esempio, memorizzando i valori selezionati nel tuo data o eseguendo altre azioni necessarie.
            const values = event.detail.value;

            console.log("Valori selezionati:", selectedValues);
            console.log("ID della domanda:", domandaId);
            // Puoi memorizzare i valori selezionati nel tuo data se necessario.
            // this.formFields[domandaId] = selectedValues;
        }

        /**
         * ! Crea sondaggio legato da associare al rapporitno
         */
        async function createSondaggio() {
            const response = {
                data: null,
                success: false,
            };

            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                savingRapportino.value = false;

                openToast("Non è stata fornita alcuna risposta alla checklist", "toast_danger");
                return response;
            }

            //Se ho un sondaggio provo a crearlo
            if (sondaggio.value) {
                // Prendo le referenze alle proprietà dell'oggetto reattivo
                const refs = toRefs(formFields.value);
                //Mi costruisco muovo oggetto nell formato corretto
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                //Mi creo l'oggetto FormData
                const formData = new FormData();
                for (const key in formattedObject) {
                    formData.append(key, formattedObject[key]);
                }
                for (const key in refs) {
                    // Se il valore è un array (selezione multipla), itera attraverso di esso
                    if (Array.isArray(refs[key].value)) {
                        for (const selectedValue of refs[key].value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // Se il valore non è un array, aggiungilo direttamente
                        formData.append(`risposta[${key}]`, refs[key].value);
                    }
                }

                formData.append("user_id", userID);
                formData.append("sondaggio_id", sondaggio.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);

                try {
                    const res = await apiSondaggi.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        return response;
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante il salvataggio della checklist", "toast_danger");
                    return response;
                } finally {
                    savingRapportino.value = false;
                }
            }
        }

        /**
         * ? Per la loro logica devo rimandare al dettaglio intervento appena creato
         * ? in maniera che possano caricare foto e firmare
         */
        async function goToDetail(res) {
            const nuovoRapportino = res;

            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: nuovoRapportino,
                    daRapportino: true,
                },
            });
            return modal.present();
        }

        /**
         * ! Reset dati form dopo submit
         */
        function resetFormData() {
            //Reset dato rapportino
            rapportino.cliente = appuntamento.appuntamenti_cliente;
            rapportino.appuntamento = appuntamento.appuntamenti_id;
            rapportino.commessa = appuntamento.appuntamenti_impianto;
            rapportino.data = moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD");
            rapportino.ora_inizio = moment(start_hour).toISOString();
            rapportino.ora_fine = moment(end_hour).toISOString();
            rapportino.operatori = [userID];
            rapportino.note = "";
            rapportino.immagini = [];
            rapportino.firma_tecnico = "";
            rapportino.firma_cliente = "";
            //Reset button firme
            customerHasSigned.value = false;
            technicianHasSigned.value = false;
        }

        /**
         * ! Salva rapportino e rimanda al dettaglio per aggiungere foto e firmare
         */
        async function saveRapportino() {
            savingRapportino.value = true;
            //Controllo che ci sia almeno un operatore selezioanto
            if (rapportino.operatori.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra gli operatori selezionati
            const isUSerSelected = rapportino.operatori.includes(userID);
            if (!isUSerSelected && rapportino.operatori.length != 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            /* const ora_inizio = new Date(rapportino.ora_inizio);
            const ora_fine = new Date(rapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            } */

            //Loading.simpleLoader("Salvataggio rapportino in corso", 10000, true);

            const ora_inizio_formatted = moment(rapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(rapportino.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_cliente", rapportino.cliente);
            data.append("rapportini_commessa", rapportino.commessa);
            data.append("rapportini_appuntamento_id", rapportino.appuntamento);
            data.append("rapportini_data", rapportino.data);
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            rapportino.operatori.forEach((tecnico) => {
                data.append("rapportini_operatori[]", tecnico);
            });
            if (rapportino.note) {
                data.append("rapportini_note", rapportino.note);
            }

            if (rapportino.firma_tecnico) {
                data.append("rapportini_firma_operatore", rapportino.firma_tecnico);
            }
            if (rapportino.firma_cliente) {
                data.append("rapportini_firma_cliente", rapportino.firma_cliente);
            }

            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            /**
             * ? Creo la compilazione e poi associo l'id della compilazione al rapportino
             */
            if (sondaggio.value) {
                const sondaggioCreato = await createSondaggio();

                //Creazione sondaggio fallita, blocco esecuzione
                if ((sondaggioCreato && !sondaggioCreato.data) || !sondaggioCreato.success) {
                    Loading.dismissLoader();
                    return;
                }

                if (sondaggioCreato && sondaggioCreato.data && sondaggioCreato.success) {
                    const compilazione_id = sondaggioCreato.data.compilazione_id;
                    data.append("rapportini_compilazione_id", compilazione_id);

                    //Dopo creato l'intervento, prima di chiudere la modale inserisco le foto nella relazione
                    try {
                        Loading.simpleLoader("Salvataggio rapportino in corso", 5000, true);

                        const response = await apiRapportini.saveRapportino(data);

                        if (response.data.status === 8) {
                            openToast(response.data.message, "toast_danger");
                        } else {
                            const res = response.data.data[0];
                            //Inserisco le immagini nella relazione
                            if (immaginiRapportino.value.length != 0) {
                                const images = immaginiRapportino.value;
                                const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                            }
                            //Svuoto array immagini
                            immaginiRapportino.value = [];
                            //Svuoto dati form
                            resetFormData();
                            //Svuoto dai sondaggio
                            Object.assign(formFields, {});

                            successResponse.value = true;
                            savingRapportino.value = false;

                            //goToDetail(res);
                            closeModalOnSubmit(successResponse, res);
                        }
                    } catch (error) {
                        console.error(error);
                        savingRapportino.value = false;
                        openToast("Errore durante la creazione del rapportino", "toast_danger");
                    } finally {
                        Loading.dismissLoader();
                    }
                }
            } else {
                //Non c'è sondaggio collegato
                try {
                    Loading.simpleLoader("Salvataggio rapportino in corso", 5000, true);

                    const response = await apiRapportini.saveRapportino(data);

                    if (response.data.status === 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        //Inserisco le immagini nella relazione
                        if (immaginiRapportino.value.length != 0) {
                            const images = immaginiRapportino.value;
                            const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                        }
                        //Svuoto array immagini
                        immaginiRapportino.value = [];
                        //Svuoto dati form
                        resetFormData();

                        successResponse.value = true;
                        Loading.dismissLoader();
                        //goToDetail(res);
                        closeModalOnSubmit(successResponse, res);
                    }
                } catch (error) {
                    console.error(error);
                    savingRapportino.value = false;
                    openToast("Errore durante la creazione del rapportino", "toast_danger");
                } finally {
                    Loading.dismissLoader();
                    setTimeout(() => {
                        savingRapportino.value = false;
                    }, 1000);
                }
            }
        }

        /**
         * ! Create new rapportino
         */
        /*async function creaRapportino() {
            savingRapportino.value = true;
            //Controllo che ci sia almeno un operatore selezioanto
            if (rapportino.operatori.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra gli operatori selezionati
            const isUSerSelected = rapportino.operatori.includes(userID);
            if (!isUSerSelected && rapportino.operatori.length != 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(rapportino.ora_inizio);
            const ora_fine = new Date(rapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }

            const ora_inizio_formatted = moment(rapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(rapportino.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_cliente", rapportino.cliente);
            data.append("rapportini_commessa", rapportino.commessa);
            data.append("rapportini_appuntamento_id", rapportino.appuntamento);
            data.append("rapportini_data", rapportino.data);
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            rapportino.operatori.forEach((tecnico) => {
                data.append("rapportini_operatori[]", tecnico);
            });
            data.append("rapportini_note", rapportino.note);

            if (rapportino.firma_tecnico) {
                data.append("rapportini_firma_operatore", rapportino.firma_tecnico);
            }
            if (rapportino.firma_cliente) {
                data.append("rapportini_firma_cliente", rapportino.firma_cliente);
            }
            //for (const value of data.entries()) {
            //    console.log(value[0] + ", " + value[1]);
            //}
            //return;

            Loading.simpleLoader("Salvataggio rapportino in corso", 5000, true);

            //Creo la compilazione e poi associo l'id della compilazione al rapportino
            const sondaggioCreato = await createSondaggio();

            //Creazione sondaggio fallita, blocco esecuzione
            if (!sondaggioCreato.data || !sondaggioCreato.success) {
                Loading.dismissLoader();
                return;
            }

            //Creazione sondaggio OK, imposto dati compilazione per creazione rapportino
            if (sondaggioCreato.data && sondaggioCreato.success) {
                const compilazione_id = sondaggioCreato.data.compilazione_id;
                data.append("rapportini_compilazione_id", compilazione_id);
                //Dopo creato l'intervento, prima di chiudere la modale inserisco le foto nella relazione
                try {
                    const response = await apiRapportini.saveRapportino(data);

                    if (response.data.status === 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        //Inserisco le immagini nella relazione
                        if (immaginiRapportino.value.length != 0) {
                            const images = immaginiRapportino.value;
                            const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                        }
                        //Svuoto array immagini
                        immaginiRapportino.value = [];

                        successResponse.value = true;
                        Loading.dismissLoader();
                        closeModalOnSubmit(successResponse, res);
                    }
                } catch (error) {
                    console.error(error);
                    savingRapportino.value = false;
                    openToast("Errore durante la creazione del rapportino", "toast_danger");
                } finally {
                    Loading.dismissLoader();
                    setTimeout(() => {
                        savingRapportino.value = false;
                    }, 1000);
                }
            }
        }*/

        onMounted(() => {
            //Ricerca sondaggio per appuntamento selezionato
            if (appuntamento.appuntamenti_checklist) {
                loadSondaggio(appuntamento.appuntamenti_checklist);
            }
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            //creaRapportino,
            savingRapportino,
            rapportino,
            //operatori,
            appuntamento,
            dayMonthFormat,
            //Gestione immagini
            openModalPhotos,
            deletePhoto,
            oreTotaliAppuntamento,
            //Firme
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            immaginiRapportino,
            //Sondaggio
            formFields,
            sondaggio,
            setRadioRisposta,
            saveRapportino,
            setCheckboxRisposta,
            loadingSondaggio,
            //Select multiple
            setMultipleSelectRisposta,
            // Flag richiesta operatori multiselect
            richiediOperatori,
            operatori,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(62, 160, 208);
    color: #ffffff;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/**
* Cehcklist
 */
.load_sondaggio_container {
    width: 100%;
    margin: 24px 0;
}

.load_sondaggio_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
/**
* Step domande
 */
.checklist_container {
    margin-top: 32px;
}
.single_step {
    margin-bottom: 32px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    margin-bottom: 4px;
}
</style>
